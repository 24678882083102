import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Welcome to BagalSe</h1>
      <p>Now buy from your nearby shops</p>
    </div>
  );
}

export default App;
